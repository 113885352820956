<template>
  <div class="news">
    <nav-bar />
    <banner :imgUrl="imgUrl" />
    <container-title
      title="服务行业"
      desc="Service trades"
      class="wow animate__slideInRight"
      data-wow-duration="1s"
    />
    <trades />
    <container-title
      title="重点合作客户"
      desc="Main customers"
      class="wow animate__slideInRight"
      data-wow-duration="1s"
    />
    <customers />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '../components/nav'
import Footer from '../components/Footer'
import banner from '../components/banner'
import containerTitle from '../components/containerTitle'
import Trades from '../components/Trades'
import Customers from '../components/Customers'
export default {
  name: 'Home',
  components: {
    NavBar,
    Footer,
    banner,
    containerTitle,
    Trades,
    Customers
  },
  data() {
    return {
      imgUrl: require('../assets/AD0I1-nNBhACGAAg9c2UyQUowImJwwIwgA84pgQ.jpg'),
      newsList: [
        {
          title: '标准化流程',
          desc:
            '现场支持服务不仅严格达成SLA，同时讲究人性化的服务软技巧，提高客户满意度'
        },
        {
          title: '定期回访',
          desc:
            '统一服务报修、服务流程的标准化和梳理、关键服务提醒和及时响应、非现场的满意度回访调查、现场抽查'
        },
        {
          title: '方案灵活',
          desc: '提供驻场服务、离岸服务，驻场+离岸混合服务'
        },
        {
          title: '极速相应',
          desc: '5*8小时工作时间故障解决，7*24小时非工作时间故障响应解决'
        },
        {
          title: '技术雄厚',
          desc: '80%以上技术人员拥有劳动局及信息产业部颁发的高级工程师资质认证'
        },
        {
          title: '费用透明',
          desc:
            '我们根据客户需要的服务内容，时间不同，提供不同形式的外包服务，经济实惠，费用透明'
        }
      ]
    }
  }
}
</script>
<style lang="less" scoped></style>
