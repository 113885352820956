<!--
 * @Description: 服务行业
 * @Author: yuany
 * @Date: 2021-03-29 22:21:33
 * @LastEditors: yuany
-->
<template>
  <div class="container">
    <div class="trades">
      <div
        class="trades-item wow animate__slideInLeft"
        data-wow-duration="1s"
        v-for="(item, index) in tradesList"
        :key="index"
      >
        <img :src="item.img" alt="" />
        <p>{{ item.title }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tradesList: [
        {
          img: require('../assets/trades-1.png'),
          title: '金融保险'
        },
        {
          img: require('../assets/trades-2.png'),
          title: '证券'
        },
        {
          img: require('../assets/trades-3.png'),
          title: '银行'
        },
        {
          img: require('../assets/trades-4.png'),
          title: '高校'
        },
        {
          img: require('../assets/trades-5.png'),
          title: '政府'
        },
        {
          img: require('../assets/trades-6.png'),
          title: '互联网'
        }
      ]
    }
  }
}
</script>
<style lang="less">
.trades {
  display: flex;
  justify-content: space-around;
  margin-bottom: 110px;
  .trades-item {
    flex: 1;
    img {
      width: 120px;
    }
    p {
      font-size: 20px;
      color: #000;
      font-weight: 500;
    }
  }
}
</style>
